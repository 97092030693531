import axios from 'axios';
import {SERVER_URL, DEVELOPMENT_SERVER_URL} from '@/config/server';


export function request(config) {
    const instance = axios.create({
        baseURL: process.env.NODE_ENV === 'development' ?
            DEVELOPMENT_SERVER_URL : SERVER_URL,
        timeout: 60 * 1000,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    instance.interceptors.request.use(
        (config) => {
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        (res) => {
            return res.data;
        },
        (error) => {
            return Promise.reject(error);
        }
    )
    return instance(config);
}
