import Vue from 'vue'
// 在App之前引入iconfont样式、公共样式
import './assets/iconfont/iconfont.css'
import './assets/css/general.css'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import Meta from 'vue-meta';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Meta);
Vue.prototype.$echarts = echarts;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
