<template>
    <div class="bodys Paper">
        <Header></Header>
        <main>
        <!-- section-精选集首屏 -->
        <section class="section-hero">
            <div class="hero">
            <div class="hero-title">
                <p class="product-name">TDA高三精选试题与解析集</p>
                <h1 class="product-slogan heading-primary">
                备考时间宝贵，做透一题胜于做过十题
                </h1>
            </div>
            <div class="hero-content">
                <div class="hero-text-box">
                <p class="hero-description">
                    <span class="paragraph"
                    ><strong
                        >《诊断性测试(TDA)高三精选试题与解析集》(简称“TDA高三精选题集”)是专为高三年级学生设计的、用于新高考高效备考的习题集。</strong
                    >TDA高三精选题集基于“做透一题胜于做过试题”的高效备考理念设计，题目全部取自<a
                        class="text-link"
                        href="#section-tda-dev-overview"
                        ><i class="ph ph-link text-icon"></i
                        >诊断性测试(TDA-G12)常规科目</a
                    >真题，由TDA教研团队结合高考命题规律、高考重难点和TDA考生作答表现等视角精心挑选得出，并以知识点作为专题、由若干专题组成试卷的方式来组织；每题除了配有详实的解析外，还包括易突破点、易错点和延伸知识点的总结。</span
                    >
                    <span class="paragraph"
                    >目前，TDA高三精选题集包含2021至2023学年的数学、物理、化学、生物共12个学科分册。</span
                    >
                </p>
                </div>
                <div class="hero-img-box img-box">
                <img
                    class="hero-img"
                    src="../assets/img/paper-hero/math-5247958_1920.webp"
                    alt="2024年TDA高三精选试题与解析集的封面示意图"
                />
                </div>
            </div>
            <div class="hero-cta">
                <div class="go-purchase-qrcode-box">
                <p class="go-purchase-qrcode-text">
                    用微信扫描以下二维码立即购买
                </p>
                <img
                    class="go-purchase-qrcode"
                    src="../assets/img/paper-hero/subject-excercise-qrcode.png"
                    alt="购买TDA精选集的网页二维码(仅支持微信扫码购买)"
                />
                </div>
                <a
                href="http://tbuypaper.thussat.cn/index"
                class="go-purchase-btn btn btn--full"
                >立即购买精选试题与解析集 &rarr;</a
                >
            </div>
            </div>
        </section>

        <!-- section-TDA试题简介 -->
        <section
            class="section-tda-dev-overview section"
            id="section-tda-dev-overview"
        >
            <div class="container">
            <div class="tda-dev-overview-content intro">
                <p class="tda-dev-overview-title">TDA试题特点</p>
                <p class="tda-dev-overview-text">
                <span class="paragraph"
                    >TDA-G12是以中国高考评价体系、普通高中课程方案和各个学科课程标准为依据，并结合大学考查视角对学生提供对接新高考模式的诊断性评价测试。TDA-G12试题(不含加试科目)整体难度适中，部分试题考查了知识和能力的综合运用，具备一定挑战性。TDA-G12试题质量高，具备高区分度、信度和效度。TDA命题专家团队是试题质量过硬的基础，拥有近200成员，均具备十年以上教学经验，五届以上高三教学经验，并参与过多次省、市、区级大规模考试原创命题工作。</span
                >
                </p>
            </div>
            </div>
        </section>

        <!-- section-精选题集特点 -->
        <section class="section-paper-features section">
            <div class="container">
            <h2 class="heading-secondary heading-secondary--features">
                <p class="section-title">
                <span class="section-title-text">精选试题与解析集特点</span>
                </p>
            </h2>
            <div class="paper-features-intro intro">
                <div class="service-feature-cards">
                <!-- 特点1 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">精题精选</p>
                    <p class="service-feature-text">
                    选题基于最新高考方向、经典解题方法与TDA实际难度值等方面考量，引导学生深化核心概念、解题策略并规避常见误区
                    </p>
                </div>

                <!-- 特点2 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">覆盖全面</p>
                    <p class="service-feature-text">
                    题目内容围绕知识点专题编排，全面覆盖学科核心知识点，体现高考对核心知识点及相关能力考查方式，帮助学生夯实基础
                    </p>
                </div>

                <!-- 特点3 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">解析详实</p>
                    <p class="service-feature-text">
                    每题配备详尽的解析，步骤完整、过程清晰、评分点明确，在帮助学生掌握解题方法的同时培养学生规范的答题习惯
                    </p>
                </div>

                <!-- 特点4 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">突破点指引</p>
                    <p class="service-feature-text">
                    每题配有解题突破点指引，明确解题思路与思考方向，助力学生在面对类似问题时，能够迅速构建出清晰的解题框架
                    </p>
                </div>

                <!-- 特点5 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">易错点提示</p>
                    <p class="service-feature-text">
                    每题配备易错点提示，针对学生解题中的常见错误，提供精准提示，帮助学生规避同类问题陷阱，提升答题的准确性与效率
                    </p>
                </div>

                <!-- 特点6 -->
                <div class="service-feature-card">
                    <p class="service-feature-title">深度延伸</p>
                    <p class="service-feature-text">
                    每题包含知识点与解题方法的深度拓展，引导学生深化对核心概念与概念间内在联系的理解，培养综合运用学科知识与技能能力
                    </p>
                </div>
                </div>
            </div>
            <div class="paper-example-intro intro">
                <h3 class="paper-exemple-title intro-title heading-tertiary">
                精选题集样图
                </h3>
                <div class="paper-example-content grid">
                <!-- 样图1 -->
                <figure class="paper-example-figure">
                    <p class="paper-example-img-title">
                    <i class="ph ph-arrow-square-right"></i>
                    <span>数学分册习题样图</span>
                    </p>
                    <img
                    class="paper-example-img"
                    src="../assets/img/paper-example/question.webp"
                    alt="数学试卷1题目截图第一页"
                    />
                </figure>

                <!-- 样图2 -->
                <figure class="paper-example-figure">
                    <p class="paper-example-img-title">
                    <i class="ph ph-arrow-square-right text-icon"></i>
                    <span>数学分册解析样图</span>
                    </p>
                    <img
                    class="paper-example-img"
                    src="../assets/img/paper-example/answer.webp"
                    alt="数学试卷1题目截图第一页"
                    />
                </figure>
                </div>
            </div>
            </div>
        </section>
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from './UI/Header';
    import Footer from './UI/Footer';

    export default {

        name: 'Paper',
        metaInfo:{
            title:"TDA高三精选试题与解析集",
            meta: [
                {
                    name : 'description',
                    content : '《诊断性测试(TDA)高三精选试题与解析集》(简称“TDA高三精选题集”)是专为高三年级学生设计的、用于新高考高效备考的习题集。TDA高三精选题集基于“做透一题胜于做过试题”的高效备考理念设计，题目全部取自诊断性测试(TDA-G12)非加试科目真题，由TDA教研团队结合高考命题规律、高考重难点和TDA考生作答表现等视角精心挑选得出，并以知识点作为专题、由若干专题组成试卷的方式来组织；每题除了配有详实的解析外，还包括易突破点、易错点和延伸知识点的总结。目前，TDA高三精选题集包含2023年和2024年的数学、物理、化学共6个学科分册。'
                }
            ]
        },
        components: {
            Header,
            Footer
        },
        data() {
            return {
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped>
    @import '../assets/css/main--paper.css';
    @import '../assets/css/queries--paper.css';
</style>
