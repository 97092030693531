<template>
    <div class="bodys Home">
        <Header></Header>
        <main>
            <!-- section-首屏 -->
            <section class="section-hero">
                <div class="hero">
                    <div class="hero-text-box">
                        <h1 class="heading-primary">
                            做专业的评价，提升学科素养，助力大中衔接
                        </h1>
                        <p class="hero-description">
                            标准学术能力测试(CSSAT，Comprehensive Standard Scholastic
                            Assessment
                            Test)是基于中学生在各学科应具备的知识和能力，并结合大学选才视角设计的学科素养评估体系，旨在通过全面、客观、准确的过程性评价，长程动态地评估学生学科素养达成度，为中学教学和学生学习提供有效反馈，促进中学教学水平和学生学科素养全面提升。
                        </p>
                        <router-link to="/tda" class="btn btn--full" style="margin:0 auto;">了解诊断性测试(TDA)时间表 &rarr;</router-link>
                    </div>
                    <div class="hero-img-box">
                        <img
                                src="../assets/img/hero/WechatIMG213.webp"
                                alt="一张有一个计算器一张纸和一支笔的示意图"
                                class="hero-img"
                        />
                    </div>
                </div>
            </section>

            <!-- section-考试命题 -->
            <section class="section-assess-dev section">
                <div class="container">
                    <span class="subheading subheading--dev">考试命题</span>
                    <h2 class="heading-secondary heading-secondary--dev">
                        试题质量过硬，对优秀学生具备良好区分度
                    </h2>
                    <div class="dev-features grid grid--3-cols">
                        <div class="dev-feature-box">
                            <div class="dev-feature-title-box">
                                <i class="ph-light ph-users-three dev-feature-icon"></i>
                                <p class="dev-feature-title">
                                    <span>专业的制题团队</span>
                                    <span>了解教学前沿动态</span>
                                </p>
                            </div>
                            <ul class="dev-feature-list">
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>中学教师来自全国各地，覆盖十余省份</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>具备十年以上教学经验，五届以上高三教学经验</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>参与多次省、市、区级大规模考试原创命题工作</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>高校教师均为985高校相关专业的专家、教授</span>
                                </li>
                            </ul>
                        </div>

                        <div class="dev-feature-box">
                            <div class="dev-feature-title-box">
                                <i class="ph-light ph-shield-check dev-feature-icon"></i>
                                <p class="dev-feature-title">
                                    <span>严谨的制题流程</span>
                                    <span>确保安全与原创性</span>
                                </p>
                            </div>
                            <ul class="dev-feature-list">
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>制题各环节均采用“背对背”原则，保证试题安全</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>各科题目均会经过严格查重，确保题目的原创性</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>覆盖命题、审题、校验、排版、校对、印刷全流程</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>制题专家及全流程相关工作人员均签订保密协议</span>
                                </li>
                            </ul>
                        </div>

                        <div class="dev-feature-box">
                            <div class="dev-feature-title-box">
                                <i class="ph-light ph-graph dev-feature-icon"></i>
                                <p class="dev-feature-title">
                                    <span>科学的试题内容</span>
                                    <span>全面考查学科素养</span>
                                </p>
                            </div>
                            <ul class="dev-feature-list">
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>根据学业质量标准，明确各学科知识考查要求</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>紧贴时代脉搏，关注教学与高考改革最新动态</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>加强情景化设计，激发创新意识，引导活学活用</span>
                                </li>
                                <li class="dev-list-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>实现大中衔接，高中基础知识结合高校选材视角</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <!-- section-考试范围 -->
            <section class="section-area section">
                <div class="container">
                    <span class="subheading">覆盖范围</span>
                    <h2 class="heading-secondary">
                        覆盖地域广，测试结果在全国、全省横向可比
                    </h2>
                    <div class="area grid">
                        <!-- 地图板块 -->
                        <div class="area-map-box">
                            <div class="area-map" id="ChineMap" ref="ChineMap"></div>
                        </div>

                        <!-- 数字板块 -->
                        <div class="area-text-box">
                            <div class="area-text">
                                <span class="area-text-data">280+</span>
                                <span class="area-text-title">城市</span>
                                <span class="area-text-aside">试题适用范围广</span>
                            </div>
                            <div class="area-text">
                                <span class="area-text-data">4800+</span>
                                <span class="area-text-title">参试中学</span>
                                <span class="area-text-aside">广泛用于中学教学评估</span>
                            </div>
                            <div class="area-text">
                                <span class="area-text-data">130万+</span>
                                <span class="area-text-title">参试人次</span>
                                <span class="area-text-aside">提供高质量评估和数据指引</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- section-考试实施 -->
            <section class="section-procedure section">
                <div class="container">
                    <span class="subheading">考试实施</span>
                    <h2 class="heading-secondary">
                        高严肃性实施标准，让评估结果更加可信、有效
                    </h2>
                    <div class="procedure-features">
                        <div class="procedure-feature">
                            <p class="procedure-feature-title">
                                <i class="ph ph-shield-checkered"></i>
                                <span>安全性</span>
                            </p>
                            <ul class="procedure-feature-list">
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>试卷制作、印刷、封装、运输全流程保密</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>采用多种加密算法对机考电子化试题加密</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>机考数据加密传输并使用专业的防攻击措施</span>
                                </li>
                            </ul>
                        </div>

                        <div class="procedure-img-box">
                            <img
                                    class="procedure-img"
                                    src="../assets/img/procedure/图片1.webp"
                                    alt=""
                            />
                        </div>

                        <div class="procedure-feature">
                            <p class="procedure-feature-title">
                                <i class="ph ph-sailboat"></i>
                                <span>稳定性</span>
                            </p>
                            <ul class="procedure-feature-list">
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>基于标准化实施流程，向中学提供明晰的实施指引</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>多种机考异常应对方案，包括断网、断电预案</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>数据监控覆盖机考各阶段供项目组提前识别风险</span>
                                </li>
                            </ul>
                        </div>

                        <div class="procedure-img-box">
                            <img
                                    class="procedure-img"
                                    src="../assets/img/procedure/图片2.webp"
                                    alt=""
                            />
                        </div>

                        <div class="procedure-img-box">
                            <img
                                    class="procedure-img"
                                    src="../assets/img/procedure/图片3.webp"
                                    alt=""
                            />
                        </div>

                        <div class="procedure-feature">
                            <p class="procedure-feature-title">
                                <i class="ph ph-gavel"></i>
                                <span>公平性</span>
                            </p>
                            <ul class="procedure-feature-list">
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>机考全程实时监控，确保考试过程可回溯</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>机考系统自动屏蔽与考试无关的应用程序</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>机考系统作答锁屏并支持切屏自动识别和预警</span>
                                </li>
                            </ul>
                        </div>

                        <div class="procedure-img-box">
                            <img
                                    class="procedure-img"
                                    src="../assets/img/procedure/图片4.webp"
                                    alt=""
                            />
                        </div>

                        <div class="procedure-feature">
                            <p class="procedure-feature-title">
                                <i class="ph ph-database"></i>
                                <span>数据可靠性</span>
                            </p>
                            <ul class="procedure-feature-list">
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>答案多途径回收、一致性检查兼防篡改校验</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>答题卡扫描图清晰度和完整性检查</span>
                                </li>
                                <li class="procedure-feature-item list-item">
                                    <i class="ph ph-check icon--list"></i>
                                    <span>成绩5‰随机抽样手工核对</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <!-- section-数据指引 -->
            <section class="section-data section">
                <div class="container">
                    <span class="subheading">数据指引</span>
                    <h2 class="heading-secondary">
                        及时反馈评估数据，为中学教学和学生学习提供有效指引
                    </h2>
                    <div class="teacher-data-features">
                        <h3 class="heading-tertiary data-features-title">
                            如果你是中学老师或领导，可以：
                        </h3>
                        <div class="data-features-content">
                            <!-- 老师文字 -->
                            <div class="data-features-text-box">
                                <div class="data-feature">
                                    <span class="data-feature-title">全面掌握学生整体表现</span>
                                    <ul class="data-feature-list">
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >了解参试数据、总成绩与各单科成绩统计数据和分布图表</span
                                            >
                                        </li>
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >了解不同难度题目表现和题型、知识点等多维度评估数据</span
                                            >
                                        </li>
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >了解学生在各科目各题答题时长集中度和离散度统计数据</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="data-feature">
                                    <span class="data-feature-title">精准识别拔尖学生</span>
                                    <ul class="data-feature-list">
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >了解综合或单科优秀（排名靠前）学生群体的评估表现</span
                                            >
                                        </li>
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >了解校内成绩优秀学生在全国或省级范围的横向对比数据</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="data-feature">
                                    <span class="data-feature-title">快速获得数据洞见</span>
                                    <ul class="data-feature-list">
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >通过柱图、饼图、箱型图等多种类型图表深入洞悉数据</span
                                            >
                                        </li>
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >查看各类图表图例和读图指引，更准确理解数据含义</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="data-feature">
                                    <span class="data-feature-title">灵活选择报告查看方式</span>
                                    <ul class="data-feature-list">
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >使用电脑浏览器，随时查看交互丰富的网页版评估报告</span
                                            >
                                        </li>
                                        <li class="data-feature-item list-item">
                                            <i class="ph ph-check icon--list"></i>
                                            <span
                                            >针对教学探讨或汇报场景，可下载PDF版评估报告查看</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- 老师图片 -->
                            <div class="data-features-img-box">
                                <img
                                        class="data-features-img teacher"
                                        src="../assets/img/data/中学.webp"
                                        alt=""
                                />
                            </div>
                        </div>
                    </div>

                    <div class="student-data-features">
                        <h3 class="heading-tertiary data-features-title">
                            如果你是学生，可以：
                        </h3>

                        <div class="data-features-content">
                            <!-- 学生图片 -->
                            <div class="data-features-img-box">
                                <img
                                        class="data-features-img student"
                                        src="../assets/img/data/学生报告.webp"
                                        alt=""
                                />
                            </div>

                            <!-- 学生文字 -->
                            <div class="data-features-text-box">
                                <ul class="data-feature-list">
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>查看总成绩与各单科成绩数据和图表</span>
                                    </li>
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>查看不同指标全国、省级或校级范围排名数据</span>
                                    </li>
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>查看题型、学科知识点等多维度评估数据</span>
                                    </li>
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>了解强基计划入围预测概率（仅限TDA序列）</span>
                                    </li>
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>通过柱图、饼图等多种类型图表深入洞悉数据</span>
                                    </li>
                                    <li class="data-feature-item list-item">
                                        <i class="ph ph-check icon--list"></i>
                                        <span>参加机考类型的评估考试可获得认证成绩单</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from './UI/Header';
    import Footer from './UI/Footer';
    import {cities} from '../assets/js/map';
    import mapjson from '../assets/js/mj.json';

    export default {
        name: 'Home',
        metaInfo:{
            title:"CSSAT、CSSATEST、TDA-标准学术能力测试（诊断性测试）",
            meta: [
                {
                    name : 'description' ,
                    content : '标准学术能力测试(CSSAT，Comprehensive Standard Scholastic Assessment Test)是基于中学生在各学科应具备的知识和能力，并结合大学选才视角设计的学科素养评估体系，旨在通过对学生学科素养达成度进行全面、客观、准确地测量和评估，为中学教学和学生学习提供有效反馈，以促进中学教学水平和学生学科素养全面提升。'
                }
            ]
        },
        components: {
            Header,
            Footer
        },
        data() {
            return {
                myChart: null
            }
        },
        mounted() {
            this.init();
            // window.addEventListener('resize', this.onResize, true)
        },
        methods: {
            onResize() {
                let that = this;
                that.myChart && that.myChart.resize()
            },
            init() {
                this.myChart = this.$echarts.init(this.$refs['ChineMap']);

                this.$echarts.registerMap("china", mapjson);

                let option = {
                    // 地理坐标系组件用于地图的绘制
                    geo: {
                        // 使用 registerMap 注册的地图名称。
                        map: "china",
                        zoom: 1.25,
                        // layoutCenter: ["50%", "50%"],
                        itemStyle: {
                            areaColor: "#e6ece9",
                            borderColor: "#333",
                            borderType: "solid",
                            borderWidth: 0.5,
                        },
                        layoutCenter: ["50%", "50%"],
                        // label: {
                        //   color: "#333",
                        //   fontSize: 10,
                        // },
                        emphasis: {
                            itemStyle: {
                                areaColor: "#e6ece9",
                            },
                            label: {
                                color: "#333",
                                fontSize: 16,
                            },
                        },
                    },

                    series: [
                        {
                            type: "effectScatter",
                            // type: "map",
                            coordinateSystem: "geo",
                            name: "geo",
                            map: "china",
                            rippleEffect: {
                                show: true,
                                brushType: "stroke",
                                scale: 3,
                                period: 7,
                                color: "#668e7c",
                            },
                            symbolSize: 5,
                            data: cities,
                            color: "#668e7c",
                        },
                    ],
                };

                this.myChart.setOption(option);

            }
        }
    }
</script>

<style scoped>
    @import '../assets/css/main.css';
    @import '../assets/css/queries.css';
</style>
