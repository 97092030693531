<template>
    <div class="top">
        <header class="header">
            <a href="#" class="logo-link">
                <img src="../../assets/img/logo.png" alt="logo" class="logo-icon"/>
                <!-- <span>标准学术能力测试</span> -->
            </a>
            <nav class="main-nav">
                <ul class="main-nav-list">
                    <li>
                        <router-link to="/" class="main-nav-link">首页</router-link>
                    </li>
                    <li>
                        <router-link to="" class="main-nav-link"
                                     @click.native="openUrl('https://mp.weixin.qq.com/s/frLJ2ygc7yqOxGrerP_HcQ')"
                        >综合学术能力评估(CACA)
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/tda" class="main-nav-link">诊断性测试(TDA)</router-link>
                    </li>
                    <!--<li>
                        <router-link to="/tda#section-intro" class="main-nav-link">TDA特点</router-link>
                    </li>-->
                    <li>
                        <router-link to="/tda#register-subsection" class="main-nav-link">TDA申请</router-link>
                    </li>
                    <div class="login-navs">
                        <li><a href="https://student.cssatest.com" class="main-nav-link nav-login">学生登录</a></li>
                        <li><a href="https://school.cssatest.com" class="main-nav-link nav-login">中学登录</a></li>
                    </div>
                </ul>
            </nav>

            <button class="btn-mobile-nav icon-mobile-nav">
                <i class="ph ph-list icon-mobile-nav" name="menu-outline"></i>
                <i class="ph ph-x icon-mobile-nav" name="close-outline"></i>
            </button>
        </header>
    </div>
</template>
<script>
    import {nextTick} from 'vue';

    export default {
        name: 'Header',
        components: {},
        mounted() {
            /**
             * 设置footer中的copyright的年份为当前年份
             */
            const yearEl = document.querySelector(".year");
            const currentYear = new Date().getFullYear();
            yearEl.textContent = currentYear;

            /**
             * 处理移动端导航交互
             */
            const btnNavEl = document.querySelector(".btn-mobile-nav");
            const headerEl = document.querySelector("header");
            const htmlEl = document.querySelector("html");

            btnNavEl.addEventListener("click", () => {
                headerEl.classList.toggle("nav-open");
                htmlEl.classList.toggle("lock");
            });

            const mainNavLinks = document.querySelectorAll(".main-nav-link");
            mainNavLinks.forEach((mainNavLink) =>
                mainNavLink.addEventListener("click", () => {
                    if (headerEl.classList.contains("nav-open")) {
                        headerEl.classList.toggle("nav-open");
                        htmlEl.classList.toggle("lock");
                    }
                })
            );

            window.addEventListener('scroll', this.getScrollPosition);
            /**
             * 页面导航固定
             */
            // document.body.classList.remove("sticky");

            nextTick(() => {
                const sectionHeroEl = document.querySelector(".section-hero");

                const obs = new IntersectionObserver(
                    (entries) => {
                        const ent = entries[0];
                        let xxx = this.getScrollPosition();
                        if (!ent.isIntersecting && xxx.y > 0) {
                            document.body.classList.add("sticky");
                        } else {
                            document.body.classList.remove("sticky");
                        }
                    },
                    {
                        // In the viewport
                        root: null,
                        threshold: 0,
                        rootMargin: "-80px",
                    }
                );
                obs.observe(sectionHeroEl);

            })


        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.getScrollPosition);
        },
        methods: {
            openUrl(url) {
                window.location.href = url;
            },
            goUrl(path, data) {
                this.$router.push({path: path, data: data})
            },
            getScrollPosition() {
                const supportPageOffset = window.pageXOffset !== undefined;
                const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

                const x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
                const y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

                return {x, y};
            }
        }
    }
</script>

<style scoped>

</style>
