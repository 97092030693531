import { request } from "./network";

export function apply(data) {
    return request({
        url: '/web/cloud/apply',
        method: 'post',
        data
    })
}

//获取省份列表
export function getProvinceList() {
    return request({
        method: "get",
        url: "/web/tools/getProvinceList",
    });
}

// 获取城市列表
export function getCityList() {
    return request({
        method: "get",
        url: "/web/tools/getCityList",
    });
}
