import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Tda from '../views/Tda.vue'
import Paper from '../views/Paper.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'CSSAT、CSSATEST、TDA-标准学术能力测试（诊断性测试）',
            description: '标准学术能力测试(CSSAT，Comprehensive Standard Scholastic Assessment Test)是基于中学生在各学科应具备的知识和能力，并结合大学选才视角设计的学科素养评估体系，旨在通过对学生学科素养达成度进行全面、客观、准确地测量和评估，为中学教学和学生学习提供有效反馈，以促进中学教学水平和学生学科素养全面提升。'
        }
    },
    {
        path: '/tda',
        name: 'tda',
        component: Tda,
        key: 'tda',
        meta: {
            title: 'CSSAT、CSSATEST、TDA-标准学术能力测试（诊断性测试）',
            description: '诊断性测试(TDA，Targeted Diagnostic Assessment)是以中国高考评价体系、普通高中课程方案和各个学科课程标准为依据，结合大学选才视角对学生提供对接新高考模式的诊断性评价，旨在通过科学的、客观的过程性评价，促进中学生学科素养提升，助力大中衔接。'
        }
    },
    {
        path: '/paper',
        name: 'paper',
        component: Paper,
        key: 'paper',
        meta: {
            title: 'TDA高三精选试题与解析集',
            description: '《诊断性测试(TDA)高三精选试题与解析集》(简称“TDA高三精选题集”)是专为高三年级学生设计的、用于新高考高效备考的习题集。TDA高三精选题集基于“做透一题胜于做过试题”的高效备考理念设计，题目全部取自诊断性测试(TDA-G12)非加试科目真题，由TDA教研团队结合高考命题规律、高考重难点和TDA考生作答表现等视角精心挑选得出，并以知识点作为专题、由若干专题组成试卷的方式来组织；每题除了配有详实的解析外，还包括易突破点、易错点和延伸知识点的总结。目前，TDA高三精选题集包含2023年和2024年的数学、物理、化学共6个学科分册。'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    window.scrollTo(0, 0);
    next();
});
export default router
