
/**
 * 显示覆盖范围内容区中的中国地图和标志
 */
export const cities = [
  {
    name: "\u77f3\u5bb6\u5e84\u5e02",
    value: ["114.46902163264978", "38.052097109846855", 11],
  },
  {
    name: "\u5510\u5c71\u5e02",
    value: ["118.18645947203979", "39.63658372414733", 3],
  },
  {
    name: "\u79e6\u7687\u5c9b\u5e02",
    value: ["119.60853063334328", "39.941748102377936", 1],
  },
  {
    name: "\u90af\u90f8\u5e02",
    value: ["114.5456282282352", "36.631262731204046", 13],
  },
  {
    name: "\u90a2\u53f0\u5e02",
    value: ["115.0316624430557", "37.07716685005908", 11],
  },
  {
    name: "\u4fdd\u5b9a\u5e02",
    value: ["115.47146383768579", "38.87998776845534", 18],
  },
  {
    name: "\u5f20\u5bb6\u53e3\u5e02",
    value: ["114.89257223145165", "40.7732372026915", 3],
  },
  {
    name: "\u627f\u5fb7\u5e02",
    value: ["117.96939750996681", "40.95785601233803", 4],
  },
  {
    name: "\u6ca7\u5dde\u5e02",
    value: ["116.84558075595014", "38.310215141107044", 2],
  },
  {
    name: "\u5eca\u574a\u5e02",
    value: ["116.69058173342549", "39.54336666275853", 3],
  },
  {
    name: "\u8861\u6c34\u5e02",
    value: ["115.6754061376161", "37.745191408077424", 20],
  },
  {
    name: "\u592a\u539f\u5e02",
    value: ["112.55639149167204", "37.87698902884778", 13],
  },
  {
    name: "\u5927\u540c\u5e02",
    value: ["113.30643625858623", "40.0824687161612", 7],
  },
  {
    name: "\u9633\u6cc9\u5e02",
    value: ["113.58761666287546", "37.862360847859385", 4],
  },
  {
    name: "\u957f\u6cbb\u5e02",
    value: ["113.12255886984902", "36.2012683721548", 7],
  },
  {
    name: "\u664b\u57ce\u5e02",
    value: ["112.85857823132879", "35.49628458647257", 5],
  },
  {
    name: "\u6714\u5dde\u5e02",
    value: ["112.4393709396677", "39.337108370541735", 9],
  },
  {
    name: "\u664b\u4e2d\u5e02",
    value: ["112.75959475565928", "37.69283940975972", 15],
  },
  {
    name: "\u8fd0\u57ce\u5e02",
    value: ["111.01338945447925", "35.03270691290923", 5],
  },
  {
    name: "\u5ffb\u5dde\u5e02",
    value: ["112.74062416023847", "38.42238338517772", 2],
  },
  {
    name: "\u4e34\u6c7e\u5e02",
    value: ["111.52553022403073", "36.093741895419726", 12],
  },
  {
    name: "\u5415\u6881\u5e02",
    value: ["111.15044967529185", "37.524497749577115", 15],
  },
  {
    name: "\u547c\u548c\u6d69\u7279\u5e02",
    value: ["111.75550856170946", "40.84842299711348", 3],
  },
  {
    name: "\u5305\u5934\u5e02",
    value: ["109.84654350721243", "40.66292878826139", 10],
  },
  {
    name: "\u4e4c\u6d77\u5e02",
    value: ["106.80039104999656", "39.6620063648907", 2],
  },
  {
    name: "\u8d64\u5cf0\u5e02",
    value: ["118.8955203975195", "42.2616861034116", 20],
  },
  {
    name: "\u901a\u8fbd\u5e02",
    value: ["122.25052178737633", "43.657980083916655", 8],
  },
  {
    name: "\u9102\u5c14\u591a\u65af\u5e02",
    value: ["109.78744317923602", "39.61448231394889", 1],
  },
  {
    name: "\u547c\u4f26\u8d1d\u5c14\u5e02",
    value: ["119.77237049946636", "49.21844647556481", 3],
  },
  {
    name: "\u5df4\u5f66\u6dd6\u5c14\u5e02",
    value: ["107.39439808372491", "40.7493594895728", 2],
  },
  {
    name: "\u5174\u5b89\u76df",
    value: ["122.04436452582519", "46.08846371321896", 1],
  },
  {
    name: "\u9521\u6797\u90ed\u52d2\u76df",
    value: ["116.05439144074573", "43.93942266533856", 1],
  },
  {
    name: "\u6c88\u9633\u5e02",
    value: ["123.47109664482264", "41.68383006919066", 19],
  },
  {
    name: "\u5927\u8fde\u5e02",
    value: ["121.62163148459285", "38.9189536667856", 21],
  },
  {
    name: "\u978d\u5c71\u5e02",
    value: ["123.00137251399407", "41.11505359694933", 3],
  },
  {
    name: "\u629a\u987a\u5e02",
    value: ["123.9643746156145", "41.88596959305694", 5],
  },
  {
    name: "\u672c\u6eaa\u5e02",
    value: ["123.69250712420832", "41.49291646055291", 5],
  },
  {
    name: "\u4e39\u4e1c\u5e02",
    value: ["124.36154728159079", "40.00640870559368", 3],
  },
  {
    name: "\u9526\u5dde\u5e02",
    value: ["121.13259630055518", "41.10093149946208", 4],
  },
  {
    name: "\u8425\u53e3\u5e02",
    value: ["122.24157466449694", "40.67313683826707", 4],
  },
  {
    name: "\u961c\u65b0\u5e02",
    value: ["121.67640799865809", "42.02802190131842", 3],
  },
  {
    name: "\u8fbd\u9633\u5e02",
    value: ["123.24336640651318", "41.27416129045421", 1],
  },
  {
    name: "\u76d8\u9526\u5e02",
    value: ["122.07749009021381", "41.12587528873715", 2],
  },
  {
    name: "\u94c1\u5cad\u5e02",
    value: ["123.73236520917769", "42.22994799718447", 1],
  },
  {
    name: "\u671d\u9633\u5e02",
    value: ["120.45749949793277", "41.57982086475567", 12],
  },
  {
    name: "\u846b\u82a6\u5c9b\u5e02",
    value: ["120.8433983399283", "40.71736443636189", 4],
  },
  {
    name: "\u957f\u6625\u5e02",
    value: ["125.3306020759069", "43.82195350104314", 27],
  },
  {
    name: "\u5409\u6797\u5e02",
    value: ["126.55563450495482", "43.84356783457924", 9],
  },
  {
    name: "\u56db\u5e73\u5e02",
    value: ["124.35648155715893", "43.171993571561", 5],
  },
  {
    name: "\u8fbd\u6e90\u5e02",
    value: ["125.15042516688747", "42.89405500574631", 3],
  },
  {
    name: "\u901a\u5316\u5e02",
    value: ["125.94660627598029", "41.733815801613424", 7],
  },
  {
    name: "\u767d\u5c71\u5e02",
    value: ["126.42963008937573", "41.93962720532889", 5],
  },
  {
    name: "\u677e\u539f\u5e02",
    value: ["124.83148187569292", "45.14740419341382", 6],
  },
  {
    name: "\u767d\u57ce\u5e02",
    value: ["122.8455906084976", "45.62550435999602", 3],
  },
  {
    name: "\u5ef6\u8fb9\u671d\u9c9c\u65cf\u81ea\u6cbb\u5dde",
    value: ["129.4773763202274", "42.91574303372181", 4],
  },
  {
    name: "\u54c8\u5c14\u6ee8\u5e02",
    value: ["126.54161509031663", "45.808825827952184", 9],
  },
  {
    name: "\u9f50\u9f50\u54c8\u5c14\u5e02",
    value: ["123.92457086841536", "47.3599771860153", 1],
  },
  {
    name: "\u9e21\u897f\u5e02",
    value: ["130.97561865876668", "45.300872317823895", 2],
  },
  {
    name: "\u9e64\u5c97\u5e02",
    value: ["130.3044328986694", "47.35605615768509", 2],
  },
  {
    name: "\u53cc\u9e2d\u5c71\u5e02",
    value: ["131.16534168078073", "46.65318589588607", 1],
  },
  {
    name: "\u5927\u5e86\u5e02",
    value: ["125.10865763402039", "46.59363317672175", 5],
  },
  {
    name: "\u4f0a\u6625\u5e02",
    value: ["128.84754638019822", "47.733318457230936", 1],
  },
  {
    name: "\u4f73\u6728\u65af\u5e02",
    value: ["130.327359092573", "46.80568999085779", 2],
  },
  {
    name: "\u4e03\u53f0\u6cb3\u5e02",
    value: ["131.01154459102744", "45.77630032154785", 1],
  },
  {
    name: "\u7261\u4e39\u6c5f\u5e02",
    value: ["129.6395397783469", "44.55624570898632", 2],
  },
  {
    name: "\u9ed1\u6cb3\u5e02",
    value: ["127.53548988621854", "50.25127231175015", 1],
  },
  {
    name: "\u7ee5\u5316\u5e02",
    value: ["126.97535687530133", "46.6600321798244", 1],
  },
  {
    name: "\u5357\u4eac\u5e02",
    value: ["118.80242172124585", "32.06465288561847", 18],
  },
  {
    name: "\u65e0\u9521\u5e02",
    value: ["120.31858328810601", "31.498809732685714", 11],
  },
  {
    name: "\u5f90\u5dde\u5e02",
    value: ["117.29057543439453", "34.21266655011306", 16],
  },
  {
    name: "\u5e38\u5dde\u5e02",
    value: ["119.98148471327892", "31.815795653327836", 8],
  },
  {
    name: "\u82cf\u5dde\u5e02",
    value: ["120.59241222959322", "31.303564074441766", 27],
  },
  {
    name: "\u5357\u901a\u5e02",
    value: ["120.90159173866185", "31.98654943120089", 13],
  },
  {
    name: "\u8fde\u4e91\u6e2f\u5e02",
    value: ["119.22862133316607", "34.60224952526725", 6],
  },
  {
    name: "\u6dee\u5b89\u5e02",
    value: ["119.02148367070623", "33.61629530103313", 8],
  },
  {
    name: "\u76d0\u57ce\u5e02",
    value: ["120.167544265761", "33.355100917626196", 15],
  },
  {
    name: "\u626c\u5dde\u5e02",
    value: ["119.41941890822997", "32.40067693609037", 7],
  },
  {
    name: "\u9547\u6c5f\u5e02",
    value: ["119.43048944567383", "32.19471592052375", 7],
  },
  {
    name: "\u6cf0\u5dde\u5e02",
    value: ["119.9295663378548", "32.4606750493083", 6],
  },
  {
    name: "\u5bbf\u8fc1\u5e02",
    value: ["118.28157403570837", "33.96774971569008", 7],
  },
  {
    name: "\u676d\u5dde\u5e02",
    value: ["120.21551180372168", "30.25308298169347", 33],
  },
  {
    name: "\u5b81\u6ce2\u5e02",
    value: ["121.62857249434141", "29.866033045866054", 15],
  },
  {
    name: "\u6e29\u5dde\u5e02",
    value: ["120.70647689035565", "28.00108540447221", 21],
  },
  {
    name: "\u5609\u5174\u5e02",
    value: ["120.76355182586005", "30.750974830920143", 10],
  },
  {
    name: "\u6e56\u5dde\u5e02",
    value: ["120.09451660915789", "30.898963937294184", 6],
  },
  {
    name: "\u7ecd\u5174\u5e02",
    value: ["120.58547847885335", "30.0363693113069", 12],
  },
  {
    name: "\u91d1\u534e\u5e02",
    value: ["119.65343619052916", "29.084639385513697", 10],
  },
  {
    name: "\u8862\u5dde\u5e02",
    value: ["118.86659674035565", "28.975545802265025", 5],
  },
  {
    name: "\u821f\u5c71\u5e02",
    value: ["122.21355631852045", "29.99091168016034", 1],
  },
  {
    name: "\u53f0\u5dde\u5e02",
    value: ["121.42743470427969", "28.66219405599615", 11],
  },
  {
    name: "\u4e3d\u6c34\u5e02",
    value: ["119.9295730584414", "28.473278180563412", 7],
  },
  {
    name: "\u5408\u80a5\u5e02",
    value: ["117.23344266497664", "31.826577833686887", 22],
  },
  {
    name: "\u829c\u6e56\u5e02",
    value: ["118.43943137653523", "31.358536655799266", 7],
  },
  {
    name: "\u868c\u57e0\u5e02",
    value: ["117.39551332813694", "32.921523704350825", 6],
  },
  {
    name: "\u6dee\u5357\u5e02",
    value: ["117.00638885071616", "32.63184739905333", 4],
  },
  {
    name: "\u9a6c\u978d\u5c71\u5e02",
    value: ["118.5135795794315", "31.676265597609103", 5],
  },
  {
    name: "\u6dee\u5317\u5e02",
    value: ["116.8045372670298", "33.96165630027632", 1],
  },
  {
    name: "\u94dc\u9675\u5e02",
    value: ["117.81847679445747", "30.95123323991339", 5],
  },
  {
    name: "\u5b89\u5e86\u5e02",
    value: ["117.06360390491879", "30.53095656804304", 11],
  },
  {
    name: "\u9ec4\u5c71\u5e02",
    value: ["118.34543725314781", "29.721889786591692", 5],
  },
  {
    name: "\u6ec1\u5dde\u5e02",
    value: ["118.33940613596579", "32.26127087204081", 6],
  },
  {
    name: "\u961c\u9633\u5e02",
    value: ["115.82043612491321", "32.89606099485221", 8],
  },
  {
    name: "\u5bbf\u5dde\u5e02",
    value: ["116.97054394561262", "33.65209532645213", 12],
  },
  {
    name: "\u516d\u5b89\u5e02",
    value: ["116.52640966418569", "31.741450815322555", 5],
  },
  {
    name: "\u4eb3\u5dde\u5e02",
    value: ["115.7844632112745", "33.850642695788835", 13],
  },
  {
    name: "\u6c60\u5dde\u5e02",
    value: ["117.49842096159624", "30.670883790764535", 3],
  },
  {
    name: "\u5ba3\u57ce\u5e02",
    value: ["118.76553424276743", "30.94660154529291", 4],
  },
  {
    name: "\u798f\u5dde\u5e02",
    value: ["119.30346983854001", "26.080429420698078", 19],
  },
  {
    name: "\u53a6\u95e8\u5e02",
    value: ["118.09643549976651", "24.485406605176305", 8],
  },
  {
    name: "\u8386\u7530\u5e02",
    value: ["119.0145209781265", "25.45986545592271", 4],
  },
  {
    name: "\u4e09\u660e\u5e02",
    value: ["117.64552116782143", "26.269736515991838", 4],
  },
  {
    name: "\u6cc9\u5dde\u5e02",
    value: ["118.68244626680422", "24.879952330498313", 16],
  },
  {
    name: "\u6f33\u5dde\u5e02",
    value: ["117.65357645298785", "24.51892979117087", 6],
  },
  {
    name: "\u5357\u5e73\u5e02",
    value: ["118.1843695481426", "26.647772874203266", 5],
  },
  {
    name: "\u9f99\u5ca9\u5e02",
    value: ["117.02344756677536", "25.081219844871676", 8],
  },
  {
    name: "\u5b81\u5fb7\u5e02",
    value: ["119.55451074542829", "26.672241711408567", 4],
  },
  {
    name: "\u5357\u660c\u5e02",
    value: ["115.86458944231661", "28.68945529506072", 16],
  },
  {
    name: "\u666f\u5fb7\u9547\u5e02",
    value: ["117.18457644638579", "29.274247711040953", 6],
  },
  {
    name: "\u840d\u4e61\u5e02",
    value: ["113.8614964337543", "27.6283927093972", 8],
  },
  {
    name: "\u4e5d\u6c5f\u5e02",
    value: ["116.00753491163063", "29.711340559079343", 13],
  },
  {
    name: "\u65b0\u4f59\u5e02",
    value: ["114.9235346513963", "27.823578697788587", 4],
  },
  {
    name: "\u9e70\u6f6d\u5e02",
    value: ["117.0755754270272", "28.265787063191418", 4],
  },
  {
    name: "\u8d63\u5dde\u5e02",
    value: ["114.9405033729825", "25.835176103497655", 28],
  },
  {
    name: "\u5409\u5b89\u5e02",
    value: ["115.00051072001253", "27.119726826070448", 18],
  },
  {
    name: "\u5b9c\u6625\u5e02",
    value: ["114.4235636759064", "27.820856421848216", 11],
  },
  {
    name: "\u629a\u5dde\u5e02",
    value: ["116.36453876864373", "27.954892253419565", 24],
  },
  {
    name: "\u4e0a\u9976\u5e02",
    value: ["117.94945960312224", "28.460625921851733", 20],
  },
  {
    name: "\u6d4e\u5357\u5e02",
    value: ["117.12639941261048", "36.65655420178723", 37],
  },
  {
    name: "\u9752\u5c9b\u5e02",
    value: ["120.38945519114627", "36.072227496663224", 48],
  },
  {
    name: "\u6dc4\u535a\u5e02",
    value: ["118.06145253489896", "36.81908568332188", 11],
  },
  {
    name: "\u67a3\u5e84\u5e02",
    value: ["117.33054194483897", "34.815994048435115", 14],
  },
  {
    name: "\u4e1c\u8425\u5e02",
    value: ["118.68138493513693", "37.43964182632334", 11],
  },
  {
    name: "\u70df\u53f0\u5e02",
    value: ["121.45441541730195", "37.470038383730525", 26],
  },
  {
    name: "\u6f4d\u574a\u5e02",
    value: ["119.16837791142822", "36.71265155126753", 36],
  },
  {
    name: "\u6d4e\u5b81\u5e02",
    value: ["116.59361234853988", "35.420177394529645", 21],
  },
  {
    name: "\u6cf0\u5b89\u5e02",
    value: ["117.0944948347959", "36.2058580448846", 24],
  },
  {
    name: "\u5a01\u6d77\u5e02",
    value: ["122.12754097831325", "37.5164305480148", 7],
  },
  {
    name: "\u65e5\u7167\u5e02",
    value: ["119.53341540456555", "35.42283899843767", 9],
  },
  {
    name: "\u4e34\u6c82\u5e02",
    value: ["118.36353300501388", "35.11067124236514", 29],
  },
  {
    name: "\u5fb7\u5dde\u5e02",
    value: ["116.36555674397471", "37.441308454576266", 14],
  },
  {
    name: "\u804a\u57ce\u5e02",
    value: ["115.99158784830443", "36.46275818769411", 16],
  },
  {
    name: "\u6ee8\u5dde\u5e02",
    value: ["117.9774040171467", "37.3881961960769", 15],
  },
  {
    name: "\u83cf\u6cfd\u5e02",
    value: ["115.48754503343376", "35.23940742476551", 11],
  },
  {
    name: "\u90d1\u5dde\u5e02",
    value: ["113.63141920733915", "34.75343885045448", 38],
  },
  {
    name: "\u5f00\u5c01\u5e02",
    value: ["114.31459258497121", "34.80288581121172", 8],
  },
  {
    name: "\u6d1b\u9633\u5e02",
    value: ["112.4594212983115", "34.62426277921943", 17],
  },
  {
    name: "\u5e73\u9876\u5c71\u5e02",
    value: ["113.19952856052156", "33.772050748691015", 8],
  },
  {
    name: "\u5b89\u9633\u5e02",
    value: ["114.39950042177432", "36.10594098401491", 10],
  },
  {
    name: "\u9e64\u58c1\u5e02",
    value: ["114.30359364247649", "35.7523574114", 2],
  },
  {
    name: "\u65b0\u4e61\u5e02",
    value: ["113.93360046733228", "35.3096399303368", 11],
  },
  {
    name: "\u7126\u4f5c\u5e02",
    value: ["113.24854783457334", "35.22096325403899", 11],
  },
  {
    name: "\u6fee\u9633\u5e02",
    value: ["115.03559747034215", "35.76759302890629", 9],
  },
  {
    name: "\u8bb8\u660c\u5e02",
    value: ["113.85847553685502", "34.04143161161871", 10],
  },
  {
    name: "\u6f2f\u6cb3\u5e02",
    value: ["114.02342077764726", "33.5877107071022", 6],
  },
  {
    name: "\u4e09\u95e8\u5ce1\u5e02",
    value: ["111.2065332238741", "34.778327249459984", 4],
  },
  {
    name: "\u5357\u9633\u5e02",
    value: ["112.53450131351325", "32.99656220465144", 16],
  },
  {
    name: "\u5546\u4e18\u5e02",
    value: ["115.66244933826238", "34.4202016658586", 13],
  },
  {
    name: "\u4fe1\u9633\u5e02",
    value: ["114.09748283304512", "32.15301454753105", 17],
  },
  {
    name: "\u5468\u53e3\u5e02",
    value: ["114.70348251482332", "33.6318288757022", 24],
  },
  {
    name: "\u9a7b\u9a6c\u5e97\u5e02",
    value: ["114.02847078173271", "33.01784241674367", 11],
  },
  {
    name: "\u6b66\u6c49\u5e02",
    value: ["114.31158155473231", "30.598466736400987", 24],
  },
  {
    name: "\u9ec4\u77f3\u5e02",
    value: ["115.04553290894361", "30.205207848941598", 3],
  },
  {
    name: "\u5341\u5830\u5e02",
    value: ["110.80452956069568", "32.63506185840116", 2],
  },
  {
    name: "\u5b9c\u660c\u5e02",
    value: ["111.29254921035434", "30.697446484492378", 3],
  },
  {
    name: "\u8944\u9633\u5e02",
    value: ["112.12853720100244", "32.014796804669224", 4],
  },
  {
    name: "\u9102\u5dde\u5e02",
    value: ["114.90160738827099", "30.39657217331699", 1],
  },
  {
    name: "\u8346\u95e8\u5e02",
    value: ["112.20639298023002", "31.041732575569622", 3],
  },
  {
    name: "\u5b5d\u611f\u5e02",
    value: ["113.92251007733665", "30.930689227018295", 6],
  },
  {
    name: "\u8346\u5dde\u5e02",
    value: ["112.24552262926137", "30.340842107742912", 8],
  },
  {
    name: "\u9ec4\u5188\u5e02",
    value: ["114.87849048410779", "30.4593588576181", 9],
  },
  {
    name: "\u54b8\u5b81\u5e02",
    value: ["114.32851909026844", "29.847055947646492", 6],
  },
  {
    name: "\u968f\u5dde\u5e02",
    value: ["113.38945001822157", "31.6965167723283", 4],
  },
  {
    name: "\u6069\u65bd\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    value: ["109.49459261857503", "30.277939575301094", 6],
  },
  {
    name: "\u957f\u6c99\u5e02",
    value: ["112.94547319535287", "28.23488939994364", 27],
  },
  {
    name: "\u682a\u6d32\u5e02",
    value: ["113.14047079776427", "27.833567639016444", 6],
  },
  {
    name: "\u6e58\u6f6d\u5e02",
    value: ["112.95046418076468", "27.835702227135585", 2],
  },
  {
    name: "\u8861\u9633\u5e02",
    value: ["112.57844721325992", "26.899576139189122", 11],
  },
  {
    name: "\u90b5\u9633\u5e02",
    value: ["111.474432885931", "27.245270272808565", 20],
  },
  {
    name: "\u5cb3\u9633\u5e02",
    value: ["113.13548942422142", "29.3631782939259", 12],
  },
  {
    name: "\u5e38\u5fb7\u5e02",
    value: ["111.70545217995837", "29.037749999406877", 9],
  },
  {
    name: "\u5f20\u5bb6\u754c\u5e02",
    value: ["110.48553254695402", "29.122815562551878", 4],
  },
  {
    name: "\u76ca\u9633\u5e02",
    value: ["112.36151595471031", "28.559711178489888", 7],
  },
  {
    name: "\u90f4\u5dde\u5e02",
    value: ["113.02146049909462", "25.776683273601865", 10],
  },
  {
    name: "\u6c38\u5dde\u5e02",
    value: ["111.61945505792227", "26.425864117900094", 9],
  },
  {
    name: "\u6000\u5316\u5e02",
    value: ["110.00851426537254", "27.575160902978517", 17],
  },
  {
    name: "\u5a04\u5e95\u5e02",
    value: ["112.00150349288418", "27.703208596991583", 10],
  },
  {
    name: "\u6e58\u897f\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    value: ["109.74557664946683", "28.317369104701186", 9],
  },
  {
    name: "\u5e7f\u5dde\u5e02",
    value: ["113.27143134445974", "23.135336306695006", 34],
  },
  {
    name: "\u97f6\u5173\u5e02",
    value: ["113.60352734562261", "24.815881278583017", 4],
  },
  {
    name: "\u6df1\u5733\u5e02",
    value: ["114.06455183658751", "22.548456637984177", 40],
  },
  {
    name: "\u73e0\u6d77\u5e02",
    value: ["113.58255478654918", "22.27656465424921", 5],
  },
  {
    name: "\u6c55\u5934\u5e02",
    value: ["116.68852864054833", "23.35909171772515", 6],
  },
  {
    name: "\u4f5b\u5c71\u5e02",
    value: ["113.12851219549718", "23.02775875078891", 8],
  },
  {
    name: "\u6c5f\u95e8\u5e02",
    value: ["113.08855619524043", "22.584603880965", 5],
  },
  {
    name: "\u6e5b\u6c5f\u5e02",
    value: ["110.36555441392824", "21.276723439012073", 6],
  },
  {
    name: "\u8302\u540d\u5e02",
    value: ["110.931542579969", "21.669064031332095", 6],
  },
  {
    name: "\u8087\u5e86\u5e02",
    value: ["112.47148894063035", "23.052888771125616", 3],
  },
  {
    name: "\u60e0\u5dde\u5e02",
    value: ["114.4235580165817", "23.116358854725593", 5],
  },
  {
    name: "\u6885\u5dde\u5e02",
    value: ["116.12953737612247", "24.294177532206206", 5],
  },
  {
    name: "\u6c55\u5c3e\u5e02",
    value: ["115.38155260365083", "22.79126303654673", 1],
  },
  {
    name: "\u6cb3\u6e90\u5e02",
    value: ["114.70744627290641", "23.749684370959752", 1],
  },
  {
    name: "\u9633\u6c5f\u5e02",
    value: ["111.98848929181268", "21.864339726138933", 3],
  },
  {
    name: "\u6e05\u8fdc\u5e02",
    value: ["113.06246832527266", "23.688230292088083", 2],
  },
  {
    name: "\u4e1c\u839e\u5e02",
    value: ["113.75842045787648", "23.02730841164339", 16],
  },
  {
    name: "\u4e2d\u5c71\u5e02",
    value: ["113.39942236263188", "22.5223146707905", 5],
  },
  {
    name: "\u6f6e\u5dde\u5e02",
    value: ["116.62947017362819", "23.662623192615886", 1],
  },
  {
    name: "\u63ed\u9633\u5e02",
    value: ["116.37851218033846", "23.555740488275585", 4],
  },
  {
    name: "\u5357\u5b81\u5e02",
    value: ["108.37345082581861", "22.822606601187154", 6],
  },
  {
    name: "\u67f3\u5dde\u5e02",
    value: ["109.43442194634564", "24.331961386852413", 4],
  },
  {
    name: "\u6842\u6797\u5e02",
    value: ["110.20354537457943", "25.242885724872647", 12],
  },
  {
    name: "\u68a7\u5dde\u5e02",
    value: ["111.28551681182014", "23.48274528113516", 3],
  },
  {
    name: "\u5317\u6d77\u5e02",
    value: ["109.126533212566", "21.48683649576942", 4],
  },
  {
    name: "\u9632\u57ce\u6e2f\u5e02",
    value: ["108.360418838298", "21.6930052899694", 1],
  },
  {
    name: "\u94a6\u5dde\u5e02",
    value: ["108.66058016842224", "21.986593539484296", 1],
  },
  {
    name: "\u8d35\u6e2f\u5e02",
    value: ["109.60552031033306", "23.117448382037534", 3],
  },
  {
    name: "\u7389\u6797\u5e02",
    value: ["110.1884531233724", "22.659830509953142", 3],
  },
  {
    name: "\u767e\u8272\u5e02",
    value: ["106.62458932565383", "23.908185934295958", 6],
  },
  {
    name: "\u6cb3\u6c60\u5e02",
    value: ["108.09149994498661", "24.698911731272894", 3],
  },
  {
    name: "\u6d77\u53e3\u5e02",
    value: ["110.32552547126409", "20.04404943925674", 6],
  },
  {
    name: "\u4e09\u4e9a\u5e02",
    value: ["109.51855670139908", "18.258736291747855", 1],
  },
  {
    name: "\u6210\u90fd\u5e02",
    value: ["104.08153351042463", "30.655821878416408", 58],
  },
  {
    name: "\u81ea\u8d21\u5e02",
    value: ["104.78444884671711", "29.345584921327575", 5],
  },
  {
    name: "\u6500\u679d\u82b1\u5e02",
    value: ["101.72554117091441", "26.58803317333301", 2],
  },
  {
    name: "\u6cf8\u5dde\u5e02",
    value: ["105.4485240693266", "28.87766830360723", 8],
  },
  {
    name: "\u5fb7\u9633\u5e02",
    value: ["104.40441936496448", "31.133115003656755", 11],
  },
  {
    name: "\u7ef5\u9633\u5e02",
    value: ["104.6855618607612", "31.473663048745863", 15],
  },
  {
    name: "\u5e7f\u5143\u5e02",
    value: ["105.85042318166482", "32.44161630531542", 6],
  },
  {
    name: "\u9042\u5b81\u5e02",
    value: ["105.5994215306444", "30.53909767110912", 13],
  },
  {
    name: "\u5185\u6c5f\u5e02",
    value: ["105.06458802499718", "29.58588653832045", 7],
  },
  {
    name: "\u4e50\u5c71\u5e02",
    value: ["103.7725376036347", "29.55794071745811", 4],
  },
  {
    name: "\u5357\u5145\u5e02",
    value: ["106.11750261487227", "30.843782508337036", 8],
  },
  {
    name: "\u7709\u5c71\u5e02",
    value: ["103.85656331579456", "30.082526119421058", 3],
  },
  {
    name: "\u5b9c\u5bbe\u5e02",
    value: ["104.6494037048691", "28.75800702855183", 11],
  },
  {
    name: "\u5e7f\u5b89\u5e02",
    value: ["106.63955268233484", "30.461746110678995", 5],
  },
  {
    name: "\u8fbe\u5dde\u5e02",
    value: ["107.47459385897544", "31.214307723927455", 8],
  },
  {
    name: "\u5df4\u4e2d\u5e02",
    value: ["106.7515853031645", "31.872888585956545", 6],
  },
  {
    name: "\u8d44\u9633\u5e02",
    value: ["104.6344353416441", "30.13495655925314", 3],
  },
  {
    name: "\u51c9\u5c71\u5f5d\u65cf\u81ea\u6cbb\u5dde",
    value: ["102.2735026809702", "27.88775230036972", 1],
  },
  {
    name: "\u8d35\u9633\u5e02",
    value: ["106.63657676352776", "26.653324822309752", 7],
  },
  {
    name: "\u516d\u76d8\u6c34\u5e02",
    value: ["104.8375546023468", "26.598833108257494", 1],
  },
  {
    name: "\u9075\u4e49\u5e02",
    value: ["106.93342774801829", "27.731700878916666", 4],
  },
  {
    name: "\u5b89\u987a\u5e02",
    value: ["105.95441712388904", "26.25925237871499", 1],
  },
  {
    name: "\u6bd5\u8282\u5e02",
    value: ["105.2985887950112", "27.29021508342595", 1],
  },
  {
    name: "\u94dc\u4ec1\u5e02",
    value: ["109.19643889817665", "27.73778645640524", 1],
  },
  {
    name: "\u9ed4\u4e1c\u5357\u82d7\u65cf\u4f97\u65cf\u81ea\u6cbb\u5dde",
    value: ["107.9894462407788", "26.58970296982603", 1],
  },
  {
    name: "\u9ed4\u5357\u5e03\u4f9d\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    value: ["107.5284027057371", "26.260616196073833", 2],
  },
  {
    name: "\u6606\u660e\u5e02",
    value: ["102.85244836500482", "24.873998150044006", 20],
  },
  {
    name: "\u66f2\u9756\u5e02",
    value: ["103.80243482794681", "25.496406931543667", 7],
  },
  {
    name: "\u7389\u6eaa\u5e02",
    value: ["102.55356029311", "24.35771094244625", 2],
  },
  {
    name: "\u4fdd\u5c71\u5e02",
    value: ["99.17727328581788", "25.139038793265964", 3],
  },
  {
    name: "\u662d\u901a\u5e02",
    value: ["103.72351177196889", "27.34408386024681", 3],
  },
  {
    name: "\u4e34\u6ca7\u5e02",
    value: ["100.09544042014869", "23.89046855627851", 1],
  },
  {
    name: "\u7ea2\u6cb3\u54c8\u5c3c\u65cf\u5f5d\u65cf\u81ea\u6cbb\u5dde",
    value: ["102.42755057757", "23.37448873836479", 2],
  },
  {
    name: "\u6587\u5c71\u58ee\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    value: ["104.22256899109433", "23.40599429361173", 1],
  },
  {
    name: "\u5927\u7406\u767d\u65cf\u81ea\u6cbb\u5dde",
    value: ["100.23651930044413", "25.59760490545194", 3],
  },
  {
    name: "\u5fb7\u5b8f\u50a3\u65cf\u666f\u9887\u65cf\u81ea\u6cbb\u5dde",
    value: ["98.59135935611411", "24.438010702758117", 1],
  },
  {
    name: "\u897f\u5b89\u5e02",
    value: ["108.94646555063274", "34.34726881662395", 26],
  },
  {
    name: "\u5b9d\u9e21\u5e02",
    value: ["107.2445753670404", "34.36891564286998", 2],
  },
  {
    name: "\u54b8\u9633\u5e02",
    value: ["108.71542245143303", "34.335476293368586", 1],
  },
  {
    name: "\u6e2d\u5357\u5e02",
    value: ["109.51658960525897", "34.50571551675255", 3],
  },
  {
    name: "\u5ef6\u5b89\u5e02",
    value: ["109.49658191612687", "36.59111103521779", 1],
  },
  {
    name: "\u6c49\u4e2d\u5e02",
    value: ["107.02943020926463", "33.073799907833795", 4],
  },
  {
    name: "\u6986\u6797\u5e02",
    value: ["109.74161603381395", "38.290883835484046", 3],
  },
  {
    name: "\u5546\u6d1b\u5e02",
    value: ["109.92441788136406", "33.87863385220776", 2],
  },
  {
    name: "\u5170\u5dde\u5e02",
    value: ["103.84052119633628", "36.067234693545565", 3],
  },
  {
    name: "\u5609\u5cea\u5173\u5e02",
    value: ["98.29620384300111", "39.77796014739059", 2],
  },
  {
    name: "\u91d1\u660c\u5e02",
    value: ["102.19460568669837", "38.52582009209263", 1],
  },
  {
    name: "\u767d\u94f6\u5e02",
    value: ["104.1444508283435", "36.55082533041454", 5],
  },
  {
    name: "\u5929\u6c34\u5e02",
    value: ["105.73141674566955", "34.58741188165064", 1],
  },
  {
    name: "\u6b66\u5a01\u5e02",
    value: ["102.64455434036918", "37.93437780815811", 1],
  },
  {
    name: "\u5f20\u6396\u5e02",
    value: ["100.45641147405634", "38.932066007004934", 3],
  },
  {
    name: "\u5e73\u51c9\u5e02",
    value: ["106.67144234827796", "35.549232050463516", 2],
  },
  {
    name: "\u9152\u6cc9\u5e02",
    value: ["98.50068521606795", "39.73846908071564", 2],
  },
  {
    name: "\u5e86\u9633\u5e02",
    value: ["107.6493856959542", "35.71521598356201", 2],
  },
  {
    name: "\u5b9a\u897f\u5e02",
    value: ["104.63242008306302", "35.586832926561875", 3],
  },
  {
    name: "\u897f\u5b81\u5e02",
    value: ["101.78445017050855", "36.62338469651661", 7],
  },
  {
    name: "\u94f6\u5ddd\u5e02",
    value: ["106.23849358740017", "38.492460055509596", 2],
  },
  {
    name: "\u77f3\u5634\u5c71\u5e02",
    value: ["106.3906004255049", "38.98968283991508", 1],
  },
  {
    name: "\u5434\u5fe0\u5e02",
    value: ["106.20537126663626", "38.00371291345338", 1],
  },
  {
    name: "\u56fa\u539f\u5e02",
    value: ["106.24857742607188", "36.02161725801098", 1],
  },
  {
    name: "\u4e2d\u536b\u5e02",
    value: ["105.20357090088713", "37.50570141870293", 2],
  },
  {
    name: "\u4e4c\u9c81\u6728\u9f50\u5e02",
    value: ["87.62443993536046", "43.830763204290484", 8],
  },
  {
    name: "\u5df4\u97f3\u90ed\u695e\u8499\u53e4\u81ea\u6cbb\u5dde",
    value: ["86.1517138653326", "41.7702873304504", 2],
  },
  {
    name: "\u5317\u4eac\u5e02",
    value: ["116.4133836971231", "39.910924547299565", 92],
  },
  {
    name: "\u5929\u6d25\u5e02",
    value: ["117.2095232146708", "39.093667843403956", 59],
  },
  {
    name: "\u4e0a\u6d77\u5e02",
    value: ["121.48053886017651", "31.235929042252014", 78],
  },
  {
    name: "\u91cd\u5e86\u5e02",
    value: ["106.55843415537664", "29.568996245338923", 85],
  },
];

// async function getData() {
//   // const url = "https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json";
//   const url = "./mj.json";
//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Response status: ${response.status}`);
//     }
//     const json = await response.json();
//     drawChinaMap(json);
//   } catch (error) {
//     console.error(error.message);
//   }
// }

// function drawChinaMap(chinaJson) {
//   let mapChart = this.$echarts.init(document.getElementById("area-map"));
//   this.$echarts.registerMap("china", chinaJson);
//   let option = {
//     // 地理坐标系组件用于地图的绘制
//     geo: {
//       // 使用 registerMap 注册的地图名称。
//       map: "china",
//       zoom: 1.25,
//       // layoutCenter: ["50%", "50%"],
//       itemStyle: {
//         areaColor: "#e6ece9",
//         borderColor: "#333",
//         borderType: "solid",
//         borderWidth: 0.5,
//       },
//       layoutCenter: ["50%", "50%"],
//       // label: {
//       //   color: "#333",
//       //   fontSize: 10,
//       // },
//       emphasis: {
//         itemStyle: {
//           areaColor: "#e6ece9",
//         },
//         label: {
//           color: "#333",
//           fontSize: 16,
//         },
//       },
//     },

//     series: [
//       {
//         type: "effectScatter",
//         // type: "map",
//         coordinateSystem: "geo",
//         name: "geo",
//         map: "china",
//         rippleEffect: {
//           show: true,
//           brushType: "stroke",
//           scale: 3,
//           period: 7,
//           color: "#668e7c",
//         },
//         symbolSize: 5,
//         data: cities,
//         color: "#668e7c",
//       },
//     ],
//   };
//   mapChart.setOption(option, true);
// }

// getData();
